// CreativeGame.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { collection, doc, setDoc, arrayUnion, writeBatch, updateDoc, deleteDoc, onSnapshot, getDoc, addDoc, getDocs, query, where, increment } from 'firebase/firestore';
import { firestore } from './firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import backgroundMusic from './song.mp3';  // Add this line
import confetti from 'canvas-confetti';
import QRCode from 'qrcode.react';
// import gametime from './gametime.png'; // Assuming you have star1.png in the same folder
import logo from './logo.png'; // Assuming you have star1.png in the same folder
import { BackButton } from './Quests'; // Adjust the path as necessary

import { runTransaction } from 'firebase/firestore';
// const peachColors = [
//     '#FFDAB9', '#FFE4B5', '#FFEFD5', '#FFE4E1', '#FFF0F5',
//     '#FAF0E6', '#FFF5EE', '#FFEBCD', '#FFE4C4', '#FAEBD7'
//   ];
  const TopicButton = styled.button`
  margin: 0 15px;
  padding: 15px 25px;
  white-space: nowrap;
  background-color: #ffffff;
  border: 2px solid #333333;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0;
  }
`;



const GameList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const GameItem = styled.li`
  margin-bottom: 10px;
`;

const GameButtonWrapper = styled.div`
  display: inline-block;
`;

const HoverActions = styled.div`
  display: none;
  margin-top: 5px;

  ${GameButtonWrapper}:hover & {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
`;

const EditForm = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const GameButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  margin: 15px;
  font-size: 24px;
  width: 280px; // Add this line to make all buttons the same width

  font-weight: bold;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  color: #000;

  
  background-color: #fff;
  border: 3px solid #000;
  cursor: pointer;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 12px;
  box-shadow: 4px 4px 0 #000;

  /* Removed text shadow for better legibility */

  &:hover:not(:disabled) {
    transform: translateY(-4px);
    box-shadow: 6px 6px 0 #000;
    background-color: #f0f0f0;
  }

  &:active:not(:disabled) {
    transform: translateY(2px);
    box-shadow: 2px 2px 0 #000;
    background-color: #e0e0e0;
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px 20px;
    margin: 8px;
  }
`;
  const ActionButton = styled(GameButton)`
  margin-left: 0px;
  padding: 5px 0px;
  width: 70px; // Add this line to make all buttons the same width

  font-size: 10px;
  
`;

const ReturnButton = styled.button`
position: absolute;
top: 10px;
left: 10px;
padding: 8px 16px;
font-size: 16px;
font-weight: bold;
font-family: Arial, sans-serif; // Changed font family
text-transform: uppercase;
color: #000;
background-color: white;
border: 2px solid #000;
cursor: pointer;
transition: all 0.2s ease;

/* Simplified comic book style */
border-radius: 8px;
box-shadow: 3px 3px 0 #000;

/* Removed text shadow for better legibility */

&:hover {
  transform: translateY(-2px);
  box-shadow: 5px 5px 0 #000;
}

&:active {
  transform: translateY(1px);
  box-shadow: 1px 1px 0 #000;
}

@media (max-width: 768px) {
  font-size: 14px;
  padding: 6px 12px;
}
`;
// Styled components (you can reuse most of these from your existing PartyGame component)
const PartyGameContainer = styled.div`
  min-height: 100vh;
  max-width: 100%;
  overflow-x: visible; // This will prevent horizontal scrolling on the page
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 12px 15px;
  font-size: 16px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s, box-shadow 0.3s;
  
  &:focus {
    border-color: #4A90E2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
    outline: none;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;
// const Select = styled.select`
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   font-size: 16px;
// `;

const NumberInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const ShareSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShareButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 12px 40px 12px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #4CAF50;
  background-color: white;
  border: 2px solid #4CAF50;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 25px;
  box-shadow: 3px 3px 0 #000;

  /* Simplified text effect */
  text-shadow: 1px 1px 0 #fff;

  /* Hover effect */
  &:hover {
    transform: translateX(3px);
    box-shadow: 5px 5px 0 #000;
  }

  /* Active effect */
  &:active {
    transform: translateX(1px);
    box-shadow: 1px 1px 0 #000;
  }

  /* Arrow icon */
  &::after {
    content: "➜";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #4CAF50;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 35px 10px 15px;
  }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const LeaderboardContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  max-width: 400px;
  width: 100%;
`;

const LeaderboardTitle = styled.h3`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const LeaderboardList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LeaderboardItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const PlayerName = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const PlayerScore = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #4CAF50;
`;

const PlayerRank = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #ff9800;
  margin-right: 10px;
`;
const MusicButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: ${props => props.isPlaying ? '#ff0000' : '#4CAF50'};
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    background-color: ${props => props.isPlaying ? '#cc0000' : '#45a049'};
  }
`;
// const QuestionText = styled.p`
//   font-size: 24px;
//   font-weight: bold;
//   margin-bottom: 20px;
//   text-align: center;
// `;

const QuestionText = styled.p`
  font-size: 28px;
  font-weight: 500;
  color: #333;
  margin-bottom: 30px;
`;
const AnswerInput = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #4A90E2; // Change border color and width
  border-radius: 15px; // Increase border radius
  resize: vertical;
  font-size: 1.2em; // Increase font size
  background-color: white; // Change background color
  color: #333; // Change text color
  transition: border-color 0.3s; // Add transition for border color
  &:focus {
    border-color: #1e90ff; // Change border color on focus
    outline: none; // Remove outline on focus
  }
`;

const SubmitButton = styled(GameButton)`
  // You can adjust styles if needed
`;

const VoteButton = styled(GameButton)`
  // You can adjust styles if needed
`;

const suggestedTopics = [
   "The science of renewable energy",
   "Creating world changing inventions",

   "Imagine yourself with Superpowers",
   "Writing the next bestselling book",

  // "Imagine yourself in 10 years",
  // "Unforgettable adventures",
  // "Your life with infinite money",
  // "Living your best life",
  // "Imagine yourself with Superpowers",
  // "Scifi inventions",
  // "Being the captain of a Yacht",
  // "Affirmations for a successful life",
//   "",
  // Add more suggested topics here
];

const InviteCodeDisplay = ({ inviteCode, showQR = true }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '10px',
      right: '10px',
      background: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.9em'
    }}>
      <span style={{ marginRight: '10px' }}>Invite Code: {inviteCode}</span>
      {showQR && <QRCode value={`https://supertutor.me/partygame?code=${inviteCode}`} size={50} />}
    </div>
  );
};


const GlidingTopics = ({ topics, onTopicSelect }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => (prevPosition - 1) % (topics.length * 300));
    }, 50);

    return () => clearInterval(interval);
  }, [topics.length]);

  return (
    <div style={{ overflow: 'hidden', width: '100%', height: '80px', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: `${position}px`,
          transition: 'left 0.05s linear',
          height: '100%',
          alignItems: 'center',
          width: 'max-content',
        }}
      >
        {[...topics, ...topics].map((topic, index) => (
          <TopicButton
            key={index}
            onClick={() => onTopicSelect(topic)}
          >
            {topic}
          </TopicButton>
        ))}
      </div>
    </div>
  );
};



const CreativeGame = () => {
    const location = useLocation();
    const handleTopicSelect = (topic) => {
        setGameTopic(topic);
      };


      const [userGames, setUserGames] = useState([]);
      const handleGameDelete = async (gameId) => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          try {
            await deleteDoc(doc(firestore, 'creativeGames', gameId));
            setUserGames(prevGames => prevGames.filter(game => game.id !== gameId));
          } catch (error) {
            console.error("Error deleting game:", error);
            alert("There was an error deleting the game. Please try again.");
          }
        }
      };

      const handleGameEdit = async (gameId, newTitle) => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          try {
            await updateDoc(doc(firestore, 'creativeGames', gameId), { topic: newTitle });
            setUserGames(prevGames => prevGames.map(game => 
              game.id === gameId ? {...game, title: newTitle} : game
            ));
          } catch (error) {
            console.error("Error updating game title:", error);
            alert("There was an error updating the game title. Please try again.");
          }
        }
      };
      

      const fetchUserGames = useCallback(async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const gamesRef = collection(firestore, 'creativeGames');
          const q = query(gamesRef, where('createdBy', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const games = querySnapshot.docs.map(doc => ({
            id: doc.id,
            title: doc.data().topic,
            createdAt: doc.data().createdAt,
            isAIGame: doc.data().isAIGame
          }));
          setUserGames(games);
        }
      }, []);
      useEffect(() => {
        fetchUserGames();
      }, [fetchUserGames]);

      const [isPlaying, setIsPlaying] = useState(false);
      const audioRef = useRef(new Audio(backgroundMusic));
          const [isHost, setIsHost] = useState(false);
  const [gameTopic, setGameTopic] = useState('');
  const [numQuestions, setNumQuestions] = useState(5);
  const [generatingGame, setGeneratingGame] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [nickname, setNickname] = useState('');
  const [waitingForPlayers, setWaitingForPlayers] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [userAnswer, setUserAnswer] = useState('');
  const [gameFinished, setGameFinished] = useState(false);
  const [gameState, setGameState] = useState(null);
  const [allPlayersAnswered, setAllPlayersAnswered] = useState(false);
  const [playersWhoAnswered, setPlayersWhoAnswered] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [allQuestionsAndAnswers, setAllQuestionsAndAnswers] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
// Add these state variables
const [votingEnded, setVotingEnded] = useState(false);
const [confettiShown, setConfettiShown] = useState({});



// const [hostVoted, setHostVoted] = useState(false);
// const [isRejoining, setIsRejoining] = useState(false);
const [playersWhoVoted, setPlayersWhoVoted] = useState([]);
const [showJoinGame, setShowJoinGame] = useState(false);
const [gameStarted, setGameStarted] = useState(false);
const [isManualCreation, setIsManualCreation] = useState(false);
const handleCreateManually = () => {
  setIsCreatingGame(false);
  setIsManualCreation(true);
};
const ManualGameCreation = ({ onSubmit }) => {
  const [gameTitle, setGameTitle] = useState('');
  const [questions, setQuestions] = useState(['']);
  const [nickname, setNickname] = useState('');

  const addQuestion = () => {
    setQuestions([...questions, '']);
  };

  const updateQuestion = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = () => {
    if (!gameTitle || questions.some(q => !q) || !nickname) {
      alert("Please fill in all fields and ensure all questions are entered.");
      return;
    }
    onSubmit(gameTitle, questions, nickname);
  };
  return (
<div style={{ display: 'flex', flexDirection: 'column' }}>
  <h2>Create Game Manually</h2>
  <StyledInput
    type="text"
    value={gameTitle}
    onChange={(e) => setGameTitle(e.target.value)}
    placeholder="Enter game title"
    style={{ marginBottom: '10px' }} // Add space between inputs
  />
  <StyledInput
    type="text"
    value={nickname}
    onChange={(e) => setNickname(e.target.value)}
    placeholder="Enter your nickname"
    style={{ marginBottom: '10px' }} // Add space between inputs
  />
  {questions.map((question, index) => (
    <textarea
      key={index}
      value={question}
      onChange={(e) => updateQuestion(index, e.target.value)}
      placeholder={`Question ${index + 1}`}
      style={{ minHeight: '60px', resize: 'vertical', marginBottom: '10px' }} // Make inputs expandable and add space between them
    />
  ))}
  <GameButton onClick={addQuestion} style={{ marginTop: '10px' }}>Add Question</GameButton>
  <GameButton onClick={handleSubmit} style={{ marginTop: '10px' }}>Create Game</GameButton>
</div>
  );
};
const handleManualGameSubmit = async (title, manualQuestions, nickname) => {
  const newInviteCode = await generateUniqueInviteCode();
  const auth = getAuth();
  const user = auth.currentUser;
  setInviteCode(newInviteCode);
  setIsManualCreation(false);
  setWaitingForPlayers(true);
  setIsHost(true);
  setGameTopic(title);
  setNumQuestions(manualQuestions.length);
  setNickname(nickname);

  const gameRef = doc(firestore, 'creativeGames', newInviteCode);
  await setDoc(gameRef, {
    topic: title,
    numQuestions: manualQuestions.length,
    currentQuestionIndex: 0,
    createdAt: new Date().toISOString(),
    questions: manualQuestions,
    isGenerating: false,
    gameStarted: false,
    gameFinished: false,
    playersWhoAnswered: [],
    playersWhoVoted: [],
    votingPhase: false,
    allVotesIn: false,
    voteCount: {},
    winningAnswerIds: [],
    hostVoted: false,
    createdBy: user.uid,
    isManualGame: true,  // Add this line
    isAIGame: false,
    hasBeenStartedBefore: false,


  });

  const playersRef = collection(gameRef, 'players');
  await addDoc(playersRef, { 
    nickname: nickname,
    score: 0,
    isHost: true
  });

  // Update local state
  setWaitingForPlayers(true);
  setGameStarted(false);
};


const handleBeginVote = async () => {
  const gameRef = doc(firestore, 'creativeGames', inviteCode);
  const gameSnapshot = await getDoc(gameRef);
  const gameData = gameSnapshot.data();

  const answersRef = collection(gameRef, `question_${currentQuestionIndex}_answers`);

  const snapshot = await getDocs(answersRef);
  const answers = snapshot.docs.map(doc => ({
    id: doc.id,
    answer: doc.data().answer,
    authorId: doc.data().authorId,
  }));

  let allAnswers = answers;

  // Only include AI answer if it's not a manual game
  if (!gameData.isManualGame) {
    const currentAiAnswer = gameData.aiAnswers && gameData.aiAnswers[currentQuestionIndex] 
      ? gameData.aiAnswers[currentQuestionIndex] 
      : "AI did not provide an answer for this question.";
    
    allAnswers = [...answers, { id: 'ai', answer: currentAiAnswer, authorId: 'SuperTutor' }];
  }
  
  const shuffledAnswers = shuffleArray(allAnswers);
  
  await updateDoc(gameRef, {
    playerAnswers: shuffledAnswers,
    votingPhase: true,
    allVotesIn: false,
    hostVoted: false,
    allPlayersAnswered: false  // Reset this flag
  });
};
const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    // For modern browsers
    return navigator.clipboard.writeText(text);
  } else {
    // Fallback for older browsers
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((resolve, reject) => {
      document.execCommand('copy') ? resolve() : reject();
      textArea.remove();
    });
  }
};


// const UserGamesList = ({ games, onGameSelect, onGameDelete, onGameEdit }) => {
//   const [editingGame, setEditingGame] = useState(null);
//   const [editedTitle, setEditedTitle] = useState('');

//   return (
//     <div>
//       <h3>My Games:</h3>
//       <ul>
//         {games.map(game => (
//           <li key={game.id}>
//             {editingGame === game.id ? (
//               <>
//                 <StyledInput
//                   type="text"
//                   value={editedTitle}
//                   onChange={(e) => setEditedTitle(e.target.value)}
//                 />
//                 <GameButton onClick={() => {
//                   onGameEdit(game.id, editedTitle);
//                   setEditingGame(null);
//                 }}>
//                   Save
//                 </GameButton>
//                 <GameButton onClick={() => setEditingGame(null)}>
//                   Cancel
//                 </GameButton>
//               </>
//             ) : (
//               <>
//                 <GameButton onClick={() => onGameSelect(game.id)}>
//                   {game.title}
//                 </GameButton>
//                 <ActionButton onClick={() => {
//   setEditingGame(game.id);
//   setEditedTitle(game.title);
// }}>
//   Edit
// </ActionButton>
// <ActionButton onClick={() => onGameDelete(game.id)}>
//   Delete
// </ActionButton>
//               </>
//             )}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };
const UserGamesList = ({ games, onGameSelect, onGameDelete, onGameEdit }) => {
  const [editingGame, setEditingGame] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  return (
    <div>
      <h3>My Games:</h3>
      <GameList>
        {games.map(game => (
          <GameItem key={game.id}>
            {editingGame === game.id ? (
              <EditForm>
                <StyledInput
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                />
                <GameButton onClick={() => {
                  onGameEdit(game.id, editedTitle);
                  setEditingGame(null);
                }}>
                  Save
                </GameButton>
                <GameButton onClick={() => setEditingGame(null)}>
                  Cancel
                </GameButton>
              </EditForm>
            ) : (
              <GameButtonWrapper>
                <GameButton onClick={() => onGameSelect(game.id)}>
                  {game.title}
                </GameButton>
                <HoverActions>
                  <ActionButton onClick={() => {
                    setEditingGame(game.id);
                    setEditedTitle(game.title);
                  }}>
                    Edit
                  </ActionButton>
                  <ActionButton onClick={() => onGameDelete(game.id)}>
                    Delete
                  </ActionButton>
                </HoverActions>
              </GameButtonWrapper>
            )}
          </GameItem>
        ))}
      </GameList>
    </div>
  );
};


const [voteCounts, setVoteCounts] = useState({});



// const handleGameSelect = async (gameId) => {
//   const gameRef = doc(firestore, 'creativeGames', gameId);
//   const gameDoc = await getDoc(gameRef);
//   if (gameDoc.exists()) {
//     const gameData = gameDoc.data();
//     setInviteCode(gameId);
//     setGameTopic(gameData.topic);
//     setNumQuestions(gameData.numQuestions);
//     setQuestions(gameData.questions);
//     setIsHost(true);
//     setWaitingForPlayers(true);
//     setIsCreatingGame(false);
//     setGameStarted(false);
    
//     // Reset game state in Firestore
//     const resetData = {
//       currentQuestionIndex: 0,
//       gameStarted: false,
//       gameFinished: false,
//       playersWhoAnswered: [],
//       playersWhoVoted: [],
//       votingPhase: false,
//       allVotesIn: false,
//       voteCount: {},
//       winningAnswerIds: [],
//       hostVoted: false,
//       hasBeenStartedBefore: false  // Reset this field

//     };

//     // If it's an AI game, preserve AI answers
//     if (gameData.isAIGame) {
//       resetData.aiAnswers = gameData.aiAnswers;
//     } else {
//       resetData.aiAnswers = [];
//     }

//     await updateDoc(gameRef, resetData);

//     // Reset player scores
//     const playersRef = collection(gameRef, 'players');
//     const playersSnapshot = await getDocs(playersRef);
//     const batch = writeBatch(firestore);

//     playersSnapshot.docs.forEach((playerDoc) => {
//       batch.update(playerDoc.ref, { score: 0 });
//     });

//     await batch.commit();

//     // Clear all answer collections for each question
//     for (let i = 0; i < gameData.numQuestions; i++) {
//       const answersRef = collection(gameRef, `question_${i}_answers`);
//       const answersSnapshot = await getDocs(answersRef);
//       answersSnapshot.docs.forEach(async (doc) => {
//         if (!doc.data().isAIAnswer) {
//           await deleteDoc(doc.ref);
//         }
//       });
//     }

//     // Update local state
//     setGameState(prevState => ({...prevState, ...resetData}));
//     setLeaderboard([]);
//     setPlayers([]);
//   }
// };
const handleGameSelect = async (gameId) => {
  const gameRef = doc(firestore, 'creativeGames', gameId);
  const gameDoc = await getDoc(gameRef);
  if (gameDoc.exists()) {
    const gameData = gameDoc.data();
    setInviteCode(gameId);
    setGameTopic(gameData.topic);
    setNumQuestions(gameData.numQuestions);
    setQuestions(gameData.questions);
    setIsHost(true);
    setWaitingForPlayers(true);
    setIsCreatingGame(false);
    setGameStarted(false);
    
    // Reset game state in Firestore
    const resetData = {
      currentQuestionIndex: 0,
      gameStarted: false,
      gameFinished: false,
      playersWhoAnswered: [],
      playersWhoVoted: [],
      votingPhase: false,
      allVotesIn: false,
      voteCount: {},
      winningAnswerIds: [],
      hostVoted: false,
      hasBeenStartedBefore: false,
      hostAdded: false  // Add this line

    };

    // If it's an AI game, preserve AI answers
    if (gameData.isAIGame) {
      resetData.aiAnswers = gameData.aiAnswers;
    } else {
      resetData.aiAnswers = [];
    }

    await updateDoc(gameRef, resetData);

    // Reset players
    const playersRef = collection(gameRef, 'players');
    const playersSnapshot = await getDocs(playersRef);
    const batch = writeBatch(firestore);

    // Delete all existing players
    playersSnapshot.docs.forEach((playerDoc) => {
      batch.delete(playerDoc.ref);
    });

    // Add current host
    const newHostRef = doc(playersRef);
    batch.set(newHostRef, { 
      nickname: nickname || 'Host',
      score: 0,
      isHost: true,
      online: true
    });

    // Add AI player if it's an AI game
    if (gameData.isAIGame) {
      const aiPlayerRef = doc(playersRef);
      batch.set(aiPlayerRef, {
        nickname: 'Supertutor',
        score: 0,
        isAI: true
      });
    }

    await batch.commit();

    // Clear all answer collections for each question
    for (let i = 0; i < gameData.numQuestions; i++) {
      const answersRef = collection(gameRef, `question_${i}_answers`);
      const answersSnapshot = await getDocs(answersRef);
      const answerBatch = writeBatch(firestore);
      answersSnapshot.docs.forEach((doc) => {
        if (!doc.data().isAIAnswer) {
          answerBatch.delete(doc.ref);
        }
      });
      await answerBatch.commit();
    }

    // Update local state
    setGameState(prevState => ({...prevState, ...resetData}));
    setLeaderboard([]);
    setPlayers([]);
    setNickname(prevNickname => prevNickname || 'Host'); // Ensure nickname is set
  }
};
// const handleGameSelect = async (gameId) => {
//   const gameRef = doc(firestore, 'creativeGames', gameId);
//   const gameDoc = await getDoc(gameRef);
//   if (gameDoc.exists()) {
//     const gameData = gameDoc.data();
//     setInviteCode(gameId);
//     setGameTopic(gameData.topic);
//     setNumQuestions(gameData.numQuestions);
//     setQuestions(gameData.questions);
//     setIsHost(true);
//     setWaitingForPlayers(true);
//     setIsCreatingGame(false);
//     setGameStarted(false);
    
//     // Reset game state in Firestore
//     const resetData = {
//       currentQuestionIndex: 0,
//       gameStarted: false,
//       gameFinished: false,
//       playersWhoAnswered: [],
//       playersWhoVoted: [],
//       votingPhase: false,
//       allVotesIn: false,
//       voteCount: {},
//       winningAnswerIds: [],
//       hostVoted: false,
//       hasBeenStartedBefore: false
//     };

//     // If it's an AI game, preserve AI answers
//     if (gameData.isAIGame) {
//       resetData.aiAnswers = gameData.aiAnswers;
//     } else {
//       resetData.aiAnswers = [];
//     }

//     await updateDoc(gameRef, resetData);

//     // Reset player scores and ensure host is added
//     const playersRef = collection(gameRef, 'players');
//     const playersSnapshot = await getDocs(playersRef);
//     const batch = writeBatch(firestore);

//     let hostExists = false;
//     playersSnapshot.docs.forEach((playerDoc) => {
//       const playerData = playerDoc.data();
//       if (playerData.isHost) {
//         hostExists = true;
//         batch.update(playerDoc.ref, { score: 0, online: true });
//       } else if (playerData.isAI) {
//         batch.update(playerDoc.ref, { score: 0 });
//       } else {
//         batch.delete(playerDoc.ref);
//       }
//     });

//     if (!hostExists) {
//       const newHostRef = doc(playersRef);
//       batch.set(newHostRef, { 
//         nickname: nickname,
//         score: 0,
//         isHost: true,
//         online: true
//       });
//     }

//     await batch.commit();

//     // Clear all answer collections for each question
//     for (let i = 0; i < gameData.numQuestions; i++) {
//       const answersRef = collection(gameRef, `question_${i}_answers`);
//       const answersSnapshot = await getDocs(answersRef);
//       answersSnapshot.docs.forEach(async (doc) => {
//         if (!doc.data().isAIAnswer) {
//           await deleteDoc(doc.ref);
//         }
//       });
//     }

//     // Update local state
//     setGameState(prevState => ({...prevState, ...resetData}));
//     setLeaderboard([]);
//     setPlayers([]);
//     setNickname(prevNickname => prevNickname || 'Host'); // Ensure nickname is set
//   }
// };




// const [aiAnswer, setAiAnswer] = useState('');
const [playerAnswers, setPlayerAnswers] = useState([]);
// const [votingPhase, setVotingPhase] = useState(false);
// At the top of your component, add these new state variables:
const [isCreatingGame, setIsCreatingGame] = useState(!location.search.includes('join=true'));
const [isJoiningGame, setIsJoiningGame] = useState(true);


// const [isJoiningGame, setIsJoiningGame] = useState(location.search.includes('join=true'));
// const [superTutorScore, setSuperTutorScore] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setIsCreatingGame(!location.search.includes('join=true'));
    setIsJoiningGame(location.search.includes('join=true'));
  }, [location.search]);

  useEffect(() => {
    const audio = audioRef.current;
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.loop = true; // This will make the audio loop
    }
  }, []);


  useEffect(() => {
    if (inviteCode && inviteCode !== '' && gameStarted) {
      const gameRef = doc(firestore, 'creativeGames', inviteCode);
      const playersRef = collection(gameRef, 'players');
      
      const unsubscribeGame = onSnapshot(gameRef, (snapshot) => {
        if (snapshot.exists()) {
          const gameData = snapshot.data();
          console.log('Game data:', gameData);
  
          setVoteCounts(gameData.voteCount || {});
          setGameState(gameData);
          setGeneratingGame(gameData.isGenerating);
          setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
          setPlayersWhoAnswered(gameData.playersWhoAnswered || []);
          setPlayersWhoVoted(gameData.playersWhoVoted || []);
          setGameFinished(gameData.gameFinished || false);
          setPlayerAnswers(gameData.playerAnswers || []);
  
          if (gameData.allPlayersAnswered && isHost) {
            setAllPlayersAnswered(true);
          } else {
            setAllPlayersAnswered(false);
          }
  
          if (gameData.questions && Array.isArray(gameData.questions)) {
            setQuestions(gameData.questions);
            setAllQuestionsAndAnswers(gameData.questions);
            setIsLoadingQuestions(false);
          } else {
            setIsLoadingQuestions(true);
          }
  
          if (gameData.gameStarted) {
            setWaitingForPlayers(false);
          }
  
          if (gameData.votingPhase) {
            setAllPlayersAnswered(false);
          }
        } else {
          setGameState(null);
          setQuestions([]);
          setIsLoadingQuestions(true);
        }
      });
  
      const unsubscribePlayers = onSnapshot(playersRef, (snapshot) => {
        const playersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPlayers(playersData);
        setLeaderboard(playersData.sort((a, b) => b.score - a.score));
      });
  
      return () => {
        unsubscribeGame();
        unsubscribePlayers();
      };
    }
  }, [inviteCode, gameStarted, isHost]);

  useEffect(() => {
    if (gameState && gameState.allVotesIn && gameState.winningAnswerIds) {
      gameState.winningAnswerIds.forEach(answerId => {
        if (!confettiShown[answerId]) {
          const confettiContainer = document.getElementById(`confetti-container-${answerId}`);
          if (confettiContainer) {
            const rect = confettiContainer.getBoundingClientRect();
            const x = (rect.left + rect.width / 2) / window.innerWidth;
            const y = (rect.top + rect.height / 2) / window.innerHeight;
      
            confetti({
              particleCount: 100,
              spread: 70,
              origin: { x, y },
              zIndex: 1000,
              disableForReducedMotion: true
            });
  
            setConfettiShown(prev => ({ ...prev, [answerId]: true }));
          }
        }
      });
    }
  }, [gameState, confettiShown]);

  // useEffect(() => {
  //   if (inviteCode && inviteCode !== '') {
  //     const gameRef = doc(firestore, 'creativeGames', inviteCode);
  //     const playersRef = collection(gameRef, 'players');
      
  //     const unsubscribeGame = onSnapshot(gameRef, (snapshot) => {
  //       if (snapshot.exists()) {
  //         const gameData = snapshot.data();
  //         console.log('Game data:', gameData);
  
  //         setGameState(gameData);
  //         setGeneratingGame(gameData.isGenerating);
  //         setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
  //         setPlayersWhoAnswered(gameData.playersWhoAnswered || []);
  //         setPlayersWhoVoted(gameData.playersWhoVoted || []);
  //         setGameFinished(gameData.gameFinished || false);
  
  //         if (gameData.questions && Array.isArray(gameData.questions)) {
  //           setQuestions(gameData.questions);
  //           setAllQuestionsAndAnswers(gameData.questions);
  //           setIsLoadingQuestions(false);
  //         } else {
  //           setIsLoadingQuestions(true);
  //         }
  
  //         if (gameData.gameStarted) {
  //           setWaitingForPlayers(false);
  //           setGameStarted(true);
  //         }
  //       } else {
  //         setGameState(null);
  //         setQuestions([]);
  //         setIsLoadingQuestions(true);
  //       }
  //     });
  
  //     const unsubscribePlayers = onSnapshot(playersRef, (snapshot) => {
  //       const playersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       setPlayers(playersData);
  //       setLeaderboard(playersData.sort((a, b) => b.score - a.score));
  //     });
  
  //     return () => {
  //       unsubscribeGame();
  //       unsubscribePlayers();
  //     };
  //   }
  // }, [inviteCode]);
  useEffect(() => {
    if (inviteCode && inviteCode !== '') {
      const gameRef = doc(firestore, 'creativeGames', inviteCode);
      const playersRef = collection(gameRef, 'players');
      
      const unsubscribeGame = onSnapshot(gameRef, (snapshot) => {
        if (snapshot.exists()) {
          const gameData = snapshot.data();
          console.log('Game data:', gameData);
  
          setGameState(gameData);
          setGeneratingGame(gameData.isGenerating);
          setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
          setPlayersWhoAnswered(gameData.playersWhoAnswered || []);
          setPlayersWhoVoted(gameData.playersWhoVoted || []);
          setGameFinished(gameData.gameFinished || false);
  
          if (gameData.questions && Array.isArray(gameData.questions)) {
            setQuestions(gameData.questions);
            setAllQuestionsAndAnswers(gameData.questions);
            setIsLoadingQuestions(false);
          } else {
            setIsLoadingQuestions(true);
          }
  
          if (gameData.gameStarted) {
            setWaitingForPlayers(false);
            setGameStarted(true);
          }
        } else {
          setGameState(null);
          setQuestions([]);
          setIsLoadingQuestions(true);
        }
      });
  
      const unsubscribePlayers = onSnapshot(playersRef, (snapshot) => {
        const playersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPlayers(playersData);
        setLeaderboard(playersData.sort((a, b) => b.score - a.score));
        
        // Only add the host if they're not already in the list and the game doesn't have the hostAdded flag
        if (isHost && !playersData.some(p => p.isHost) && !gameState?.hostAdded) {
          addDoc(playersRef, {
            nickname: nickname,
            score: 0,
            isHost: true,
            online: true,
            joinedAtQuestion: currentQuestionIndex
          }).then(() => {
            // Update the game to indicate the host has been added
            updateDoc(gameRef, { hostAdded: true });
          });
        }
      });
  
      return () => {
        unsubscribeGame();
        unsubscribePlayers();
      };
    }
  }, [inviteCode, isHost, nickname, currentQuestionIndex, gameState]);

  // const handleJoinGameSubmit = async () => {
  //   if (!inviteCode || !nickname) {
  //     alert("Please enter both invite code and nickname");
  //     return;
  //   }
  
  //   const gameRef = doc(firestore, 'creativeGames', inviteCode);
  //   const gameDoc = await getDoc(gameRef);
  
  //   if (gameDoc.exists()) {
  //     const gameData = gameDoc.data();
  //     const playersRef = collection(gameRef, 'players');
  //     const playerQuery = query(playersRef, where('nickname', '==', nickname));
  //     const playerSnapshot = await getDocs(playerQuery);
  
  //     let playerDocRef;
  
  //     if (playerSnapshot.empty) {
  //       playerDocRef = await addDoc(playersRef, { 
  //         nickname, 
  //         score: 0,
  //         joinedAtQuestion: gameData.currentQuestionIndex || 0
  //       });
  //       console.log(`New player ${nickname} added to the game.`);
  //     } else {
  //       playerDocRef = playerSnapshot.docs[0].ref;
  //       console.log(`Existing player ${nickname} rejoined the game.`);
  //     }
  
  //     await updateDoc(playerDocRef, { online: true });
  
  //     setIsJoiningGame(false);
  //     setShowJoinGame(false);
  //     setWaitingForPlayers(true);  // Set this to true for guests
  //     setIsHost(false);
  //     setNickname(nickname);
  //     setGameStarted(gameData.gameStarted);
  //     setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
  
  //     if (gameData.gameStarted) {
  //       if (gameData.votingPhase) {
  //         await updateDoc(gameRef, {
  //           playersWhoAnswered: arrayUnion(nickname),
  //           playersWhoVoted: arrayUnion(nickname)
  //         });
  //       }
  //       setWaitingForPlayers(false);  // If game has started, don't show waiting room
  //     }
  
  //     console.log(`Player ${nickname} successfully joined/rejoined the game.`);
  //   } else {
  //     alert('Invalid invite code. Please enter a valid invite code.');
  //   }
  // };
  const handleJoinGameSubmit = async () => {
    if (!inviteCode || !nickname) {
      alert("Please enter both invite code and nickname");
      return;
    }
  
    const gameRef = doc(firestore, 'creativeGames', inviteCode);
  
    try {
      const gameDoc = await getDoc(gameRef);
  
      if (gameDoc.exists()) {
        const gameData = gameDoc.data();
        const playersRef = collection(gameRef, 'players');
        const playerQuery = query(playersRef, where('nickname', '==', nickname));
        const playerSnapshot = await getDocs(playerQuery);
  
        let playerDocRef;
  
        if (playerSnapshot.empty) {
          playerDocRef = await addDoc(playersRef, { 
            nickname, 
            score: 0,
            joinedAtQuestion: gameData.currentQuestionIndex || 0
          });
          console.log(`New player ${nickname} added to the game.`);
        } else {
          playerDocRef = playerSnapshot.docs[0].ref;
          console.log(`Existing player ${nickname} rejoined the game.`);
        }
  
        await updateDoc(playerDocRef, { online: true });
  
        setIsJoiningGame(false);
        setShowJoinGame(false);
        setWaitingForPlayers(gameData.gameStarted ? false : true);
        setIsHost(false);
        setNickname(nickname);
        setConfettiShown({});

        setGameStarted(gameData.gameStarted);
        setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
  
        if (gameData.gameStarted) {
          if (gameData.votingPhase) {
            await updateDoc(gameRef, {
              playersWhoAnswered: arrayUnion(nickname),
              playersWhoVoted: arrayUnion(nickname)
            });
          }
        }
  
        console.log(`Player ${nickname} successfully joined/rejoined the game.`);
      } else {
        alert('Invalid invite code. Please enter a valid invite code.');
      }
    } catch (error) {
      console.error("Error joining game:", error);
      alert("There was an error joining the game. Please try again.");
    }
  };

  const generateUniqueInviteCode = async () => {
    let isUnique = false;
    let inviteCode;
  
    while (!isUnique) {
      inviteCode = generateNumericCode(5);
      const publicGameRef = doc(firestore, 'publicGames', inviteCode);
      const creativeGameRef = doc(firestore, 'creativeGames', inviteCode);
      const publicGameDoc = await getDoc(publicGameRef);
      const creativeGameDoc = await getDoc(creativeGameRef);
      
      if (!publicGameDoc.exists() && !creativeGameDoc.exists()) {
        isUnique = true;
      }
    }
  
    return inviteCode;
  };
  const generateNumericCode = (length) => {
    return Array.from({ length }, () => Math.floor(Math.random() * 10)).join('');
  };
  
  const handleGenerateGame = async () => {
    if (!gameTopic.trim()) {
      alert("Please enter a game topic before creating the game.");
      return;
    }
    const newInviteCode = await generateUniqueInviteCode();
    
    setInviteCode(newInviteCode);
    setIsCreatingGame(false);
  
    setWaitingForPlayers(true);
    setIsHost(true);
    setGeneratingGame(true);
  
    const auth = getAuth();
    const user = auth.currentUser;
    const userDoc = await getDoc(doc(firestore, 'users', user.uid));
    const userData = userDoc.data();
    const firstName = userData.name ? userData.name.split(" ")[0] : 'User';
    const hostNickname = `${firstName}`;
    setNickname(hostNickname);
  
    const gameRef = doc(firestore, 'creativeGames', newInviteCode);
    await setDoc(gameRef, {
      topic: gameTopic,
      numQuestions,
      currentQuestionIndex: 0,
      createdAt: new Date().toISOString(),
      questions: [],
      isGenerating: true,
      gameStarted: false,
      gameFinished: false,
      aiAnswers: [],
      createdBy: user.uid,
      isAIGame: true,
      hasBeenStartedBefore: false,
      hostAdded: true  // Add this line
    });
  
    const playersRef = collection(gameRef, 'players');
    await addDoc(playersRef, { 
      nickname: hostNickname,
      score: 0,
      isHost: true
    });
    await addDoc(playersRef, {
      nickname: 'Supertutor',
      score: 0,
      isAI: true
    });
    try {
      const response = await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/generateCreativeGame', {
        topic: gameTopic,
        inviteCode: newInviteCode,
        numQuestions,
      });
  
      if (response.data.success) {
        setGeneratingGame(false);
        setWaitingForPlayers(true);
        setIsCreatingGame(false);
      } else {
        throw new Error('Failed to generate game');
      }
    } catch (error) {
      console.error('Error generating creative game:', error);
      alert('An error occurred while generating the creative game. Please try again.');
      setGeneratingGame(false);
    }
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => console.log("Audio play failed:", error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const handleReturnClick = () => {
    navigate('/');
  };



  const handleStartGame = async () => {
    const gameRef = doc(firestore, 'creativeGames', inviteCode);
    
    try {
      const gameDoc = await getDoc(gameRef);
      const gameData = gameDoc.data();
  
      // Check if this is a new game or a relaunch
      const isRelaunch = gameData.hasBeenStartedBefore;
  
      if (isRelaunch) {
        // Clear all players except host and AI for relaunched games
        const playersRef = collection(gameRef, 'players');
        const playersSnapshot = await getDocs(playersRef);
        
        const batch = writeBatch(firestore);
        playersSnapshot.docs.forEach(doc => {
          if (!doc.data().isHost && !doc.data().isAI) {
            batch.delete(doc.ref);
          } else {
            // Reset scores for host and AI
            batch.update(doc.ref, { score: 0 });
          }
        });
        await batch.commit();
      }
  
      await updateDoc(gameRef, { 
        gameStarted: true,
        hasBeenStartedBefore: true,
        currentQuestionIndex: 0,
        playersWhoAnswered: [],
        playersWhoVoted: [],
        votingPhase: false,
        allVotesIn: false,
        voteCount: {},
        winningAnswerIds: [],
        hostVoted: false
      });
  
      // Update local state
      setCurrentQuestionIndex(0);
      setPlayersWhoAnswered([]);
      setPlayersWhoVoted([]);
      setWaitingForPlayers(false);
      setGameState(prevState => ({ ...prevState, gameStarted: true }));
      setGameStarted(true);
      setConfettiShown({});

      // Update players list in local state
      const updatedPlayersSnapshot = await getDocs(collection(gameRef, 'players'));
      const updatedPlayers = updatedPlayersSnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      setPlayers(updatedPlayers);
  
      console.log("Game started successfully");
    } catch (error) {
      console.error("Error starting game:", error);
      alert("There was an error starting the game. Please try again.");
    }
  };



  const handleEndVote = async () => {
    const gameRef = doc(firestore, 'creativeGames', inviteCode);
    await updateDoc(gameRef, { allVotesIn: true });
    await calculateScores();
    setVotingEnded(true);
    
    // Add this line:
    setGameState(prevState => ({ ...prevState, allVotesIn: true }));
  };

//   const handleSubmitAnswer = async () => {
//     if (!userAnswer.trim()) {
//       alert("Please enter an answer before submitting.");
//       return;
//     }
  
//     if (playersWhoAnswered.includes(nickname)) {
//       alert("You've already submitted an answer for this question.");
//       return;
//     }
  
//     const gameRef = doc(firestore, 'creativeGames', inviteCode);
//     const answersRef = collection(gameRef, `question_${currentQuestionIndex}_answers`);
//     await addDoc(answersRef, {
//       answer: userAnswer,
//       authorId: nickname,
//     });
  
//     setUserAnswer('');
  
//     // Update Firestore
//     await updateDoc(gameRef, {
//       playersWhoAnswered: arrayUnion(nickname)
//     });
  
//     const gameSnapshot = await getDoc(gameRef);
//     const gameData = gameSnapshot.data();
//     const humanPlayers = players.filter(player => !player.isAI);
  
//     // Check if all human players have answered
//   if (gameData.playersWhoAnswered.length >= humanPlayers.length) {
//     if (isHost) {
//       // If the host is the last to answer, automatically begin voting
//       await handleBeginVote();
//     } else {
//       // If all players have answered but the host hasn't started voting yet,
//       // update the game state to indicate that all players have answered
//       await updateDoc(gameRef, {
//         allPlayersAnswered: true
//       });
//       setAllPlayersAnswered(true);
//     }
//   }
// };

// const handleSubmitAnswer = async () => {
//   if (!userAnswer.trim()) {
//     alert("Please enter an answer before submitting.");
//     return;
//   }

//   const gameRef = doc(firestore, 'creativeGames', inviteCode);
//   const gameSnapshot = await getDoc(gameRef);
//   const gameData = gameSnapshot.data();

//   const playerRef = doc(collection(gameRef, 'players'), players.find(p => p.nickname === nickname).id);
//   const playerSnapshot = await getDoc(playerRef);
//   const playerData = playerSnapshot.data();

//   if (playerData.joinedAtQuestion > currentQuestionIndex) {
//     alert("You joined after this question was asked. Please wait for the next question.");
//     return;
//   }

//   if (playersWhoAnswered.includes(nickname)) {
//     alert("You've already submitted an answer for this question.");
//     return;
//   }

//   const answersRef = collection(gameRef, `question_${currentQuestionIndex}_answers`);
//   await addDoc(answersRef, {
//     answer: userAnswer,
//     authorId: nickname,
//   });

//   setUserAnswer('');

//   // Update Firestore
//   await updateDoc(gameRef, {
//     playersWhoAnswered: arrayUnion(nickname)
//   });

//   const humanPlayers = players.filter(player => !player.isAI);

//   // Check if all human players have answered
//   if (gameData.playersWhoAnswered.length >= humanPlayers.length) {
//     if (isHost) {
//       // If the host is the last to answer, automatically begin voting
//       await handleBeginVote();
//     } else {
//       // If all players have answered but the host hasn't started voting yet,
//       // update the game state to indicate that all players have answered
//       await updateDoc(gameRef, {
//         allPlayersAnswered: true
//       });
//       setAllPlayersAnswered(true);
//     }
//   }
// };
const handleSubmitAnswer = async () => {
  if (!userAnswer.trim()) {
    alert("Please enter an answer before submitting.");
    return;
  }

  const gameRef = doc(firestore, 'creativeGames', inviteCode);
  const gameSnapshot = await getDoc(gameRef);
  const gameData = gameSnapshot.data();

  // Check if the current player exists in the players array
  let currentPlayer = players.find(p => p.nickname === nickname);
  
  // If the player doesn't exist, add them to the game
  if (!currentPlayer) {
    const playersRef = collection(gameRef, 'players');
    const newPlayerRef = await addDoc(playersRef, {
      nickname: nickname,
      score: 0,
      isHost: isHost,
      online: true,
      joinedAtQuestion: currentQuestionIndex
    });
    currentPlayer = { id: newPlayerRef.id, nickname: nickname };
    setPlayers(prevPlayers => [...prevPlayers, currentPlayer]);
  }

  if (playersWhoAnswered.includes(nickname)) {
    alert("You've already submitted an answer for this question.");
    return;
  }

  const answersRef = collection(gameRef, `question_${currentQuestionIndex}_answers`);
  await addDoc(answersRef, {
    answer: userAnswer,
    authorId: nickname,
  });

  setUserAnswer('');

  // Update Firestore
  await updateDoc(gameRef, {
    playersWhoAnswered: arrayUnion(nickname)
  });

  const humanPlayers = players.filter(player => !player.isAI);

  // Check if all human players have answered
  if (gameData.playersWhoAnswered.length >= humanPlayers.length) {
    if (isHost) {
      // If the host is the last to answer, automatically begin voting
      await handleBeginVote();
    } else {
      // If all players have answered but the host hasn't started voting yet,
      // update the game state to indicate that all players have answered
      await updateDoc(gameRef, {
        allPlayersAnswered: true
      });
      setAllPlayersAnswered(true);
    }
  }
};
  const calculateScores = useCallback(async () => {
    const gameRef = doc(firestore, 'creativeGames', inviteCode);
    const gameSnapshot = await getDoc(gameRef);
    const gameData = gameSnapshot.data();
    const voteCount = gameData.voteCount || {};
  
    const playerRef = collection(gameRef, 'players');
    
    // Calculate new scores
    for (const [answerId, votes] of Object.entries(voteCount)) {
      const winningAnswer = playerAnswers.find(answer => answer.id === answerId);
      if (winningAnswer) {
        let playerQuery;
        if (winningAnswer.authorId === 'SuperTutor') {
          playerQuery = query(playerRef, where('nickname', '==', 'Supertutor'));
        } else {
          playerQuery = query(playerRef, where('nickname', '==', winningAnswer.authorId));
        }
        
        const playerSnapshot = await getDocs(playerQuery);
        
        if (!playerSnapshot.empty) {
          const playerDoc = playerSnapshot.docs[0];
          await updateDoc(playerDoc.ref, {
            score: increment(votes * 10) // Each vote is worth 10 points
          });
        }
      }
    }
    
    // Update leaderboard
    const updatedPlayers = await getDocs(playerRef);
    const playerScores = updatedPlayers.docs.map(doc => ({
      nickname: doc.data().nickname,
      score: doc.data().score
    }));
    
    setLeaderboard(playerScores.sort((a, b) => b.score - a.score));
    
    // Find the winning answer(s)
    const maxVotes = Math.max(...Object.values(voteCount));
    const winningAnswerIds = Object.keys(voteCount).filter(id => voteCount[id] === maxVotes);
    
    await updateDoc(gameRef, { 
      allVotesIn: true,
      winningAnswerIds: winningAnswerIds
    });
  
    const updatedGameSnapshot = await getDoc(gameRef);
    setGameState(updatedGameSnapshot.data());
    
    // Trigger confetti for winning answers
  // Trigger confetti for winning answers
winningAnswerIds.forEach(answerId => {
  const confettiContainer = document.getElementById(`confetti-container-${answerId}`);
  if (confettiContainer) {
    const rect = confettiContainer.getBoundingClientRect();
    const x = (rect.left + rect.width / 2) / window.innerWidth;
    const y = (rect.top + rect.height / 2) / window.innerHeight;

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x, y },
      zIndex: 1000,
      disableForReducedMotion: true
    });
  }
});
  }, [inviteCode, playerAnswers]);

  const handleVote = async (votedAnswerId) => {
    if (playersWhoVoted.includes(nickname)) {
      console.log('You have already voted for this question');
      return;
    }
  
    setSelectedAnswer(votedAnswerId);
  
    const gameRef = doc(firestore, 'creativeGames', inviteCode);
    
    try {
      await runTransaction(firestore, async (transaction) => {
        const gameDoc = await transaction.get(gameRef);
        const gameData = gameDoc.data();
        const currentVoters = gameData.playersWhoVoted || [];
        
        if (currentVoters.includes(nickname)) {
          throw new Error("You have already voted");
        }
  
        const newVoteCount = (gameData.voteCount?.[votedAnswerId] || 0) + 1;
        const newPlayersWhoVoted = [...currentVoters, nickname];
  
        transaction.update(gameRef, {
          [`voteCount.${votedAnswerId}`]: newVoteCount,
          playersWhoVoted: newPlayersWhoVoted,
          hostVoted: isHost
        });
      });
  
      setPlayersWhoVoted(prev => [...prev, nickname]);
      console.log("Vote submitted. Is host:", isHost);
  
    } catch (error) {
      console.error("Error submitting vote:", error.message);
    }
  };


const handleNextQuestion = async () => {
  const gameRef = doc(firestore, 'creativeGames', inviteCode);
  const nextQuestionIndex = currentQuestionIndex + 1;
  setVotingEnded(false);  // Add this line
  setConfettiShown({}); // Reset confetti state

  if (nextQuestionIndex < numQuestions) {
    await updateDoc(gameRef, {
      currentQuestionIndex: nextQuestionIndex,
      playersWhoAnswered: [],
      playersWhoVoted: [],
      votingPhase: false,
      voteCount: {},
      hostVoted: false,
      allVotesIn: false  // Add this line

    });
    setCurrentQuestionIndex(nextQuestionIndex);
    setPlayerAnswers([]);
    setSelectedAnswer(null);
    setPlayersWhoVoted([]);
    setPlayersWhoAnswered([]);
    setVoteCounts({});
  } else {
    // Game is finished
    await updateDoc(gameRef, { 
      gameFinished: true,
      votingPhase: false
    });
    setGameFinished(true);
  }
};
      if (showJoinGame) {
        return (
          <PartyGameContainer>

            <h2>Join a Creative Game</h2>
            <StyledInput
              type="text"
              placeholder="Enter invite code"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />
            <StyledInput
              type="text"
              placeholder="Enter your nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <GameButton onClick={handleJoinGameSubmit}>Join Game</GameButton>
          </PartyGameContainer>
        );
      }
return (
<PartyGameContainer>
<MusicButton onClick={togglePlay} isPlaying={isPlaying}>
      {isPlaying ? '❚❚' : '▶'}
    </MusicButton>
    {/* {inviteCode && <InviteCodeDisplay inviteCode={inviteCode} showQR={!waitingForPlayers} />} */}
    {isHost && inviteCode && <InviteCodeDisplay inviteCode={inviteCode} showQR={!waitingForPlayers} />}
  {isCreatingGame && !waitingForPlayers && (
    <>
                    {/* <ReturnButton onClick={handleReturnClick}>&#8592; </ReturnButton> */}
                    <BackButton to="/">
        <img src="/supertutorlogo.png" alt="SuperTutor Logo" />
      </BackButton>
                    <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>Are you smarter than Supertutor?</h2>
      <StyledInput
        type="text"
        value={gameTopic}
        onChange={(e) => setGameTopic(e.target.value)}
        placeholder="Enter your game topic here"
      />
      <div style={{ width: '100%', minWidth: '800px', overflow: 'hidden' }}>
      {/* <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.2em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>Or pick a topic:</h2> */}
            <Label>Or pick a topic:</Label>

      <GlidingTopics topics={suggestedTopics} onTopicSelect={handleTopicSelect} />
          </div>
          <FormGroup>
            <Label>Number of Questions:</Label>
            <NumberInput
              type="number"
              min={2}
              max={10}
              value={numQuestions}
              onChange={(e) => setNumQuestions(parseInt(e.target.value))}
            />
          </FormGroup>
      
          <GameButton onClick={handleGenerateGame} disabled={generatingGame}>
  {generatingGame && <Spinner />}
  {generatingGame ? 'Generating Game...' : 'Create Game with AI'}
</GameButton>
<GameButton onClick={handleCreateManually}>Create Game Manually</GameButton>
<UserGamesList 
  games={userGames} 
  onGameSelect={handleGameSelect} 
  onGameDelete={handleGameDelete}
  onGameEdit={handleGameEdit}
/>

        </>
      )}
  
  {isManualCreation && (
    <ManualGameCreation onSubmit={(title, questions, nickname) => handleManualGameSubmit(title, questions, nickname)} />
)}

{isJoiningGame && (
  <>
    <ReturnButton onClick={handleReturnClick}>&#8592; </ReturnButton>
    <h2>Join the imagination game</h2>
    <StyledInput
      type="text"
      placeholder="Enter invite code"
      value={inviteCode}
      onChange={(e) => setInviteCode(e.target.value)}
    />
    <StyledInput
      type="text"
      placeholder="Enter your nickname"
      value={nickname}
      onChange={(e) => setNickname(e.target.value)}
    />
    <GameButton onClick={handleJoinGameSubmit}>Join Game</GameButton>
  </>
)}


{waitingForPlayers && !isHost && (
  <>
    <h2>Waiting for the host to start the game...</h2>
    <h3>Joined Players:</h3>
    <ul>
      {players.map((player, index) => (
        <li key={index}>{player.nickname} {player.isHost ? '(Host)' : ''}</li>
      ))}
    </ul>
  </>
)}

{waitingForPlayers && isHost && (
  <>
      <h2>Invite players to join the game or play with yourself</h2>
    <ShareSection>
      <h3>Invite Code: {inviteCode}</h3>
      <p>Guests can join at supertutor.me/partygame</p>
      <QRCode value={`https://supertutor.me/partygame?code=${inviteCode}`} />
      <ShareButton onClick={() => {
        const shareText = `Join my Supertutor Trivia Game! Go to https://supertutor.me/partygame and use code ${inviteCode}`;
        
        copyToClipboard(shareText)
          .then(() => {
            if (navigator.share) {
              navigator.share({
                title: 'Join my SuperTutorAI Party Game',
                text: shareText,
                url: 'https://supertutor.me/partygame'
              });
            } else {
              // Fallback for browsers that don't support Web Share API
              alert(`Text copied to clipboard!\n\nShare this text:\n\n${shareText}`);
            }
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
            alert('Failed to copy text. Please copy it manually:\n\n' + shareText);
          });
      }}>
        Share Invite
      </ShareButton>
    </ShareSection>
    <h3>Joined Players:</h3>
    <ul>
      {players.map((player, index) => (
        <li key={index}>{player.nickname} {player.isHost ? '(Host)' : ''}</li>
      ))}
    </ul>

    {isHost && (
  <GameButton onClick={handleStartGame} disabled={generatingGame}>
    {generatingGame && <Spinner />}
    {generatingGame ? 'Generating Game...' : 'Start Game'}
  </GameButton>
)}
  </>
)}
  
  {gameStarted && !gameFinished && !isJoiningGame && (
  <>
    {/* <img src={gametime} alt="Multiplayer game" style={{ width: '100px' }} /> */}
    <img src={logo} alt="Multiplayer game" style={{ width: '100px' }} />

    {isLoadingQuestions ? (
      <p>Loading questions...</p>
    ) : (
      <>
        {!gameState.votingPhase ? (
          <>
            {questions && questions.length > currentQuestionIndex && (
              <QuestionText>{questions[currentQuestionIndex]}</QuestionText>
            )}
            <AnswerInput
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Type your answer here"
              disabled={playersWhoAnswered.includes(nickname)}
            />
            <SubmitButton 
              onClick={handleSubmitAnswer}
              disabled={playersWhoAnswered.includes(nickname)}
            >
              Submit Answer
            </SubmitButton>

            {playersWhoAnswered.includes(nickname) && (
              <p>You have submitted your answer. Waiting for the host to begin voting...</p>
            )}

            {/* {isHost && (
              <GameButton 
                onClick={handleBeginVote}
                disabled={playersWhoAnswered.length === 0}
              >
                Begin Vote
              </GameButton>
            )} */}
{isHost && !gameState.votingPhase && (
  <GameButton 
    onClick={handleBeginVote}
    disabled={false}
  >
    {allPlayersAnswered ? 'All Players Answered - Begin Vote' : 'Begin Vote'}
  </GameButton>
)}

            {/* Players who answered section */}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <h4>Players who answered:</h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {playersWhoAnswered.map(player => (
                  <div key={player} style={{
                    margin: '5px',
                    padding: '5px 10px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '15px',
                    fontSize: '14px'
                  }}>
                    {player}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (

  <>
    <QuestionText>{questions[currentQuestionIndex]}</QuestionText>
    <h3>Vote for the best answer:</h3>
  

    {playerAnswers.map((answer, index) => {
  const isWinningAnswer = gameState.allVotesIn && gameState.winningAnswerIds && gameState.winningAnswerIds.includes(answer.id);
  const voteCount = voteCounts[answer.id] || 0;

  return (
    <div key={answer.id}>
      {isWinningAnswer && gameState.allVotesIn && (
        <div style={{ position: 'relative' }}>
          <div id={`confetti-container-${answer.id}`} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }} />
        </div>
      )}
      {isWinningAnswer && gameState.allVotesIn && <p style={{ color: '#4CAF50', fontWeight: 'bold' }}>Winning Answer</p>}
      <VoteButton 
        onClick={() => handleVote(answer.id)}
        disabled={playersWhoVoted.includes(nickname) || gameState.allVotesIn}
        style={{
          backgroundColor: isWinningAnswer && gameState.allVotesIn ? '#4CAF50' : (selectedAnswer === answer.id ? '#f0f0f0' : '#fff'),
          color: isWinningAnswer && gameState.allVotesIn ? '#fff' : '#000',
          border: isWinningAnswer && gameState.allVotesIn ? '2px solid #45a049' : '2px solid #000',
        }}
      >
        Answer {index + 1}: {answer.answer}
        {gameState.allVotesIn && <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>({voteCount} vote{voteCount !== 1 ? 's' : ''})</span>}
      </VoteButton>
    </div>
  );
})}

  
    
    {/* Players who voted section */}

    {gameState?.votingPhase && isHost && !gameState.allVotesIn && (
  <GameButton 
    onClick={handleEndVote}
    disabled={false}
  >
    End Vote
  </GameButton>
)}

    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <h4>Players who voted:</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {playersWhoVoted.map(player => (
          <div key={player} style={{
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: '#f0f0f0',
            borderRadius: '15px',
            fontSize: '14px'
          }}>
            {player}
          </div>
        ))}
      </div>
    </div>
  </>
)}

    {isHost && gameState.votingPhase && votingEnded && (
  <GameButton onClick={handleNextQuestion}>
    Next Question
  </GameButton>
)}

{selectedAnswer && (
  <p>Your selected answer: {playerAnswers.find(a => a.id === selectedAnswer)?.answer}</p>
)}
           
              <LeaderboardContainer>
                <LeaderboardTitle>Leaderboard</LeaderboardTitle>
                <LeaderboardList>
                  {leaderboard.map((player, index) => (
                    <LeaderboardItem key={index}>
                      <PlayerRank>{index + 1}.</PlayerRank>
                      <PlayerName>{player.nickname}</PlayerName>
                      <PlayerScore>{player.score}</PlayerScore>
                    </LeaderboardItem>
                  ))}
                </LeaderboardList>
              </LeaderboardContainer>
            </>
          )}
        </>
      )}
  
      {gameFinished && (
        <>
          <h2>Game Over</h2>
          <LeaderboardContainer>
            <LeaderboardTitle>Leaderboard</LeaderboardTitle>
            <LeaderboardList>
              {leaderboard.map((player, index) => (
                <LeaderboardItem key={player.nickname}>
                  <PlayerRank>{index + 1}.</PlayerRank>
                  <PlayerName>{player.nickname}</PlayerName>
                  <PlayerScore>{player.score}</PlayerScore>
                </LeaderboardItem>
              ))}
            </LeaderboardList>
          </LeaderboardContainer>
          <GameButton onClick={() => navigate('/partygame')}>New Game</GameButton>

          <GameButton onClick={() => navigate('/')}>Return to Home</GameButton>
          <div>
            {/* <h3>All Questions and Answers:</h3> */}
            {allQuestionsAndAnswers.map((qa, index) => (
              <div key={index}>
        
              </div>
            ))}
          </div>
        </>
      )}
        <div id="confetti-container" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 9999 }} />

    </PartyGameContainer>
  );
};

export default CreativeGame;