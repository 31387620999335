import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import styled, { keyframes, css } from 'styled-components';
import { collection, addDoc, Timestamp, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { FaTimes, FaPlus, FaTrash } from 'react-icons/fa';
import { firestore, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaImage, FaYoutube } from 'react-icons/fa';
import { debounce } from 'lodash';
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const inputStyles = css`
 width: 100%;
  box-sizing: border-box; // Add this to include padding in the width calculation
  max-width: 100%; // Add this to prevent overflow
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #007AFF;
    box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.2);
  }
`;

const Input = styled.input`
  ${inputStyles}
`;


const TextArea = styled.textarea`
  ${inputStyles}
  resize: vertical;
  min-height: 80px;
  white-space: pre-wrap; // Add this line
`;
// Add this new styled component for the resizable input
const ResizableInput = styled(TextArea)`
  min-height: 40px;
`;

// Add these styled components
const SavingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const SavingProgress = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const LastSavedText = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
`;



const MediaInput = styled.div`
  margin-top: 10px;
`;

const MediaTypeSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const MediaTypeButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: ${props => props.selected ? '#2196f3' : '#e0e0e0'};
  color: ${props => props.selected ? 'white' : 'black'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ImagePreview = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 20px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 122, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0); }
`;

const FormContainer = styled.div`
  padding: 40px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
  border: 2px solid #e0e0e0;
  position: relative;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(to right, #007AFF, #00C7FF);
  }

  @media (max-width: 768px) {
    padding: 20px;
    width: 95%;
  }
`;
// Add this with your other styled components
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 1);
    transform: scale(1.1);
  }
`;
const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.5px;
`;


const Select = styled.select`
  ${inputStyles}
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
  padding-right: 30px;
`;

const Button = styled.button`
  padding: 10px 16px; // Reduce from 12px 20px
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-right: 10px;
  font-family: inherit;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
  }

  ${props => props.isPrimary && css`
    animation: ${pulse} 2s infinite;
  `}
`;

const LevelContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  animation: ${slideIn} 0.3s ease-out;
  border: 2px solid #e0e0e0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(to right, #FFA500, #FF4500);
  }
`;

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
`;

const QuestionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px; // Reduce from 20px
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 15px;
`;

const IconButton = styled(Button)`
  background-color: #dc3545;
  padding: 6px 10px; // Reduce from 8px 12px
  font-size: 12px;

  &:hover {
    background-color: #c82333;
  }
`;

const QuestionTitle = styled.h4`
  font-size: 20px;
  color: #007AFF;
  margin-bottom: 15px;
  font-weight: 600;
`;

const CreateQuest = ({ user, onClose, editingQuest }) => {
  // const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(editingQuest?.imageUrl || null);
  const [lastSaved, setLastSaved] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [currentEditingQuest, setCurrentEditingQuest] = useState(editingQuest);
  const [savingProgress, setSavingProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState(editingQuest?.imageUrl || null);

  const { control, handleSubmit, register, reset, trigger } = useForm({
    defaultValues: useMemo(() => ({
      questName: editingQuest?.name || '',
      levels: editingQuest?.levels || [{
        levelNumber: 1,
        levelTitle: 'The Challenge Begins',
        questions: [{
          question: '',
          options: ['', '', '', ''],
          correctAnswer: '',
          explanation: ''
        }]
      }]
    }), [editingQuest]),
    mode: 'onChange'
  });

  const handleFieldChange = useCallback(() => {
    if (trigger) {
      trigger();
    }
  }, [trigger]);
  // Watch all fields, including nested fields
  const watchAllFields = useWatch({ control });
  const [questionMedia, setQuestionMedia] = useState(editingQuest ? editingQuest.levels.map(level => 
    level.questions.map(question => question.media || { type: null, content: '' })
  ) : []);

  const saveDraft = useCallback(async (data) => {
    console.log('Attempting to save draft:', data);
    if (!data || !data.questName) return; // Don't save if essential data is missing
  
    try {
      const draftData = {
        name: data.questName,
        levels: data.levels?.map((level, levelIndex) => ({
          levelNumber: level.levelNumber,
          levelTitle: level.levelTitle,
          questions: level.questions?.map((question, questionIndex) => ({
            ...question,
            media: questionMedia[levelIndex]?.[questionIndex] || { type: null, content: '' }
          }))
        })) || [],
        isDraft: true,
        updatedAt: Timestamp.now(),
        createdBy: {
          uid: user.uid,
          name: user.displayName || 'Anonymous'
        },
        imageUrl: imageUrl
      };
  
      let docId = currentEditingQuest?.id;
      if (docId) {
        await updateDoc(doc(firestore, 'quests', docId), draftData);
        console.log('Updated existing quest:', docId);
      } else {
        const docRef = await addDoc(collection(firestore, 'quests'), {
          ...draftData,
          createdAt: Timestamp.now()
        });
        docId = docRef.id;
        setCurrentEditingQuest({ id: docId, ...draftData });
        console.log('Created new quest:', docId);
      }
      console.log('Draft auto-saved successfully!');
    } catch (error) {
      console.error('Error auto-saving draft:', error);
    }
  }, [user, questionMedia, imageUrl, currentEditingQuest, setCurrentEditingQuest]);
  // Create a memoized debounced save function
  const debouncedSave = useMemo(
    () => debounce(async (data) => {
      console.log('Debounced save triggered', data);
      await saveDraft(data);
      setLastSaved(new Date());
    }, 1000),
    [saveDraft]
  );
  useEffect(() => {
    console.log('Form data changed:', watchAllFields);
  }, [watchAllFields]);
  // Use a single useEffect for auto-save
  useEffect(() => {
    if (user && watchAllFields) {
      console.log('Form data changed, triggering auto-save');
      debouncedSave(watchAllFields);
    }
  }, [user, debouncedSave, watchAllFields]);

  const handleQuestionImageChange = (levelIndex, questionIndex, e) => {
    const file = e.target.files[0];
    if (file) {
      handleQuestionImageUpload(levelIndex, questionIndex, file);
    }
  };

// Near the top of the CreateQuest component



const handleImageChange = async (e) => {
  const file = e.target.files[0];
  if (file) {
    // Remove this line:
    // setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
    
    // Upload the image immediately and update the imageUrl
    const storageRef = ref(storage, `quest_images/${file.name}`);
    await uploadBytes(storageRef, file);
    const newImageUrl = await getDownloadURL(storageRef);
    setImageUrl(newImageUrl);
  }
};


 

const { fields: levelFields, append: appendLevel, remove: removeLevel } = useFieldArray({
  control,
  name: "levels"
});


// Add the new cleanup effect here
  useEffect(() => {
    return () => {
      if (currentEditingQuest && !currentEditingQuest.name) {
        // Delete the empty quest when component unmounts
        deleteDoc(doc(firestore, 'quests', currentEditingQuest.id));
      }
    };
  }, [currentEditingQuest]);
  
  useEffect(() => {
    if (editingQuest) {
      reset({
        questName: editingQuest.name,
        levels: editingQuest.levels.map(level => ({
          levelNumber: level.levelNumber,
          levelTitle: level.levelTitle,
          questions: level.questions.map(question => ({
            question: question.question,
            options: question.options,
            correctAnswer: question.correctAnswer,
            explanation: question.explanation
          }))
        }))
      });
      setCurrentEditingQuest(editingQuest);
      setImagePreview(editingQuest.imageUrl);
      setQuestionMedia(editingQuest.levels.map(level => 
        level.questions.map(question => question.media || { type: null, content: '' })
      ));
    } else {
      // Reset questionMedia when creating a new quest
      setQuestionMedia([]);
    }
  }, [editingQuest, reset]);
 
  
  
  
  const handleQuestionImageUpload = async (levelIndex, questionIndex, file) => {
    if (file) {
      const storageRef = ref(storage, `quest_images/${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      
      const newQuestionMedia = [...questionMedia];
      if (!newQuestionMedia[levelIndex]) {
        newQuestionMedia[levelIndex] = [];
      }
      newQuestionMedia[levelIndex][questionIndex] = { type: 'image', content: imageUrl };
      setQuestionMedia(newQuestionMedia);
    }
  };

  
 
  const onSubmit = async (data) => {
    console.log('Starting onSubmit');
    console.log('Current editing quest:', currentEditingQuest);
    console.log('Image URL:', imageUrl);
  
    setIsSaving(true);
    setSavingProgress(0);
    try {
      const questData = {
        name: data.questName,
        imageUrl: imageUrl,
        createdBy: {
          uid: user.uid,
          name: user.displayName || 'Anonymous'
        },
        updatedAt: Timestamp.now(),
        isDraft: false,
        isPublic: false,
        id: currentEditingQuest?.id || null,
        levels: data.levels.map((level, levelIndex) => ({
          levelNumber: levelIndex + 1,
          levelTitle: level.levelTitle,
          questions: level.questions.map((q, qIndex) => ({
            questionNumber: qIndex + 1,
            question: q.question,
            options: q.options,
            correctAnswer: q.correctAnswer,
            explanation: q.explanation,
            media: questionMedia[levelIndex]?.[qIndex] || { type: null, content: '' }
          }))
        }))
      };
  
      console.log('Quest data to be saved:', questData);

      let questId = currentEditingQuest?.id;
      if (!questId) {
        const docRef = await addDoc(collection(firestore, 'quests'), {
          ...questData,
          createdAt: Timestamp.now()
        });
        questId = docRef.id;
        console.log('New quest created with ID:', questId);
      } else {
        await updateDoc(doc(firestore, 'quests', questId), questData);
        console.log('Existing quest updated with ID:', questId);
      }
  
      setIsSaving(false);
      console.log('Quest saved successfully');
      alert('Quest saved successfully!');
      onClose();
    } catch (error) {
      console.error('Error saving quest:', error);
      alert('Failed to save quest. Please try again.');
      setIsSaving(false);
    }
  };

  return (
    <FullScreenWrapper>
      
      <FormContainer>
        <CloseButton onClick={onClose}><FaTimes /></CloseButton>
        <Title>{editingQuest ? 'Edit Your Epic Quest' : 'Create an Epic Quest'}</Title>
        
        <form onSubmit={handleSubmit(onSubmit)}>

          
          <Label>Quest Name</Label>
          <Input
  {...register('questName', { 
    required: true,
    onChange: handleFieldChange
  })}
  placeholder="Enter your quest's epic title"
/>

          <Label>Quest Image</Label>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          {imagePreview && <ImagePreview src={imagePreview} />}
          
 {levelFields.map((level, levelIndex) => (
  <LevelContainer key={level.id}>
    <Input
      {...register(`levels.${levelIndex}.levelTitle`, { 
        required: true,
        onChange: handleFieldChange
      })}
      placeholder="Enter the level title"
    />
<Questions
  nestIndex={levelIndex}
  {...{ control, register }}
  questionMedia={questionMedia}
  setQuestionMedia={setQuestionMedia}
  handleQuestionImageUpload={handleQuestionImageUpload}
  handleQuestionImageChange={handleQuestionImageChange}
  handleFieldChange={handleFieldChange}
/>
    <IconButton type="button" onClick={() => removeLevel(levelIndex)}>
      <FaTrash /> Remove This Level
    </IconButton>
  </LevelContainer>
))}
<Button
  type="button"
  onClick={() => {
    appendLevel({
      levelNumber: levelFields.length + 1,
      levelTitle: `Level ${levelFields.length + 1}: The Challenge Continues`,
      questions: [{
        question: '',
        options: ['', '', '', ''],
        correctAnswer: '',
        explanation: ''
      }]
    });
    handleFieldChange();
  }}
>
  <FaPlus /> Add Another Epic Level
</Button>

          
     <ButtonContainer>
     <Button type="button" onClick={handleSubmit(onSubmit)} $isPrimary disabled={isSaving}>
  Save and Close
</Button>
</ButtonContainer>

{lastSaved && (
  <LastSavedText>
    Last auto-saved: {lastSaved.toLocaleTimeString()}
  </LastSavedText>
)}
</form>

{isSaving && (
  <SavingOverlay>
    <SavingProgress>
      <p>Saving quest... {Math.round(savingProgress)}%</p>
    </SavingProgress>
  </SavingOverlay>
)}
</FormContainer>
</FullScreenWrapper>
);
};

const Questions = ({ nestIndex, control, trigger, register, questionMedia, setQuestionMedia, handleQuestionImageUpload, handleQuestionImageChange, handleFieldChange }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `levels[${nestIndex}].questions`
  });

  return (
    <div>
      {fields.map((item, k) => (
        <QuestionContainer key={item.id}>
          <QuestionTitle>Question {k + 1}: The Mind-Bender</QuestionTitle>
          <Label>Question</Label>
          <ResizableInput
  {...register(`levels[${nestIndex}].questions[${k}].question`, { 
    required: true,
    onChange: handleFieldChange
  })}
  placeholder="Enter your challenging question"
/>
          <Label>Options</Label>
          <OptionContainer>
            {[0, 1, 2, 3].map((optionIndex) => (
              <Input
                key={optionIndex}
                {...register(`levels[${nestIndex}].questions[${k}].options[${optionIndex}]`, { required: true })}
                placeholder={`Option ${optionIndex + 1}`}
                defaultValue={item.options[optionIndex]}
                required
              />
            ))}
          </OptionContainer>
          <Label>Correct Answer</Label>
          <Select
            {...register(`levels[${nestIndex}].questions[${k}].correctAnswer`, { required: true })}
            defaultValue={item.correctAnswer}
            required
          >
            <option value="">Select correct option</option>
            {[1, 2, 3, 4].map((optionNumber) => (
              <option key={optionNumber} value={optionNumber}>
                Option {optionNumber}
              </option>
            ))}
          </Select>
          <Label>Explanation / Fun Fact</Label>
          <TextArea
            {...register(`levels[${nestIndex}].questions[${k}].explanation`)}
            placeholder="Enlighten the player with an explanation or fun fact"
          />
        <MediaInput>
            <MediaTypeSelector>
              <MediaTypeButton
                type="button"
                selected={questionMedia[nestIndex]?.[k]?.type === 'image'}
                onClick={() => setQuestionMedia(prev => {
                  const newMedia = [...prev];
                  if (!newMedia[nestIndex]) newMedia[nestIndex] = [];
                  newMedia[nestIndex][k] = { type: 'image', content: newMedia[nestIndex]?.[k]?.content || '' };
                  return newMedia;
                })}
              >
                <FaImage /> Image
              </MediaTypeButton>
              <MediaTypeButton
                type="button"
                selected={questionMedia[nestIndex]?.[k]?.type === 'youtube'}
                onClick={() => setQuestionMedia(prev => {
                  const newMedia = [...prev];
                  if (!newMedia[nestIndex]) newMedia[nestIndex] = [];
                  newMedia[nestIndex][k] = { type: 'youtube', content: newMedia[nestIndex]?.[k]?.content || '' };
                  return newMedia;
                })}
              >
                <FaYoutube /> YouTube
              </MediaTypeButton>
            </MediaTypeSelector>
            {questionMedia[nestIndex]?.[k]?.type === 'image' && (
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => handleQuestionImageChange(nestIndex, k, e)}
              />
            )}
          {questionMedia[nestIndex]?.[k]?.type === 'youtube' && (
              <Input
                type="text"
                placeholder="Enter YouTube video URL"
                value={questionMedia[nestIndex]?.[k]?.content || ''}
                onChange={(e) => {
                  const newMedia = [...questionMedia];
                  if (!newMedia[nestIndex]) newMedia[nestIndex] = [];
                  newMedia[nestIndex][k] = { type: 'youtube', content: e.target.value };
                  setQuestionMedia(newMedia);
                }}
              />
            )}
          </MediaInput>
        <IconButton type="button" onClick={() => remove(k)}>
          <FaTrash /> Remove This Question
        </IconButton>
      </QuestionContainer>
    ))}
<Button
  type="button"
  onClick={() => {
    append({
      question: '',
      options: ['', '', '', ''],
      correctAnswer: '',
      explanation: ''
    });
    // Trigger form update
    trigger();
  }}
>
      <FaPlus /> Add Another Mind-Bending Question
    </Button>
  </div>
);
};

export default CreateQuest;